<template>
  <div>
    <div class="startDetail" v-show="isShowUpload">
      <span>
        <a-upload
          name="file"
          :multiple="true"
          action="/api/dscloud-app-forum/web/year-pond/uploadPond"
          accept=".xlsx"
          :headers="headers"
          :showUploadList="false"
          @change="handleChangeFile"
        >
              <a-button type="primary"> <a-icon type="upload" /> 上传 </a-button>
            </a-upload>
        <a-button type="link" style="margin-left: 15px" @click="downloadTemplate">下载模板</a-button>
      </span>
    </div>
    <div>
      <a-table :pagination="false" :columns="columns" :data-source="data" bordered>
        <span slot="packetName"><span class="packetName">*</span> 奖包名称</span>
        <span slot="wight"><span class="rule">*</span> 中奖概率 （%）</span>
        <span slot="prizeTitle"><span class="prizeTitle">*</span> 奖品名称</span>
        <span slot="prizeType"><span class="requiredClass">*</span> 奖品类型</span>
        <span slot="relevancyId"><span class="requiredClass">*</span> 关联ID</span>
        <span slot="size"><span class="requiredClass">*</span> 数量</span>
        <span slot="prizeImg"><span class="requiredClass">*</span> 奖品图片</span>
        <template
          slot="prizeTitle"
          slot-scope="text, record, index"
        >
          {{ text }}
        </template>
        <template
          slot="prizeType"
          slot-scope="text, record, index"
        >
          {{ text === 1 ? "优惠券" : text === 2 ? "积分" : text === 3 ? '现金' : '实物' }}
        </template>
        <template
          slot="relevancyId"
          slot-scope="text, record, index"
        >
          {{ text }}
        </template>
        <template
          slot="isGround"
          slot-scope="text, record, index"
        >
          {{ text === "1" ? "是" : "否" }}
        </template>
        <template
          slot="size"
          slot-scope="text, record, index"
        >
          {{ text }}
        </template>
        <template
          slot="crowds"
          slot-scope="text, record, index"
        >
          {{ text ? crowdsList.find(arr => arr.id === text).crowdName : "" }}
        </template>
        <template
          slot="probability"
          slot-scope="text, record, index"
        >
          {{ text }}
        </template>
        <template
          slot="prizeImg"
          slot-scope="text, record, index"
        >
          <a-upload
            action="#"
            list-type="picture-card"
            :disabled="true"
            :file-list="[{
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: text,
            }]"
          >
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="text" />
          </a-modal>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getStartPrizeInfoById } from "@/api/yearEndActivities";

export default {
  name: "StartPrize",
  data() {
    return {
      data: [],
      // 当前奖池ID
      currentJackpotId: '',
      isShowUpload: true,
      previewVisible: false,
      fileList: "",
      headers: { Authorization: JSON.parse(localStorage.getItem("user")).accessToken },
      editingKey: "",
      columns: [
        {
          dataIndex: "packetName",
          slots: { title: "packetName" },
          key: 'packetName',
          scopedSlots: { customRender: "packetName" },
          width: "15%",
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {}
            };
            if (row.isMarge) {
              obj.attrs.rowSpan = this.calculationRowSpan(row);
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          }
        },
        {
          slots: { title: "wight" },
          dataIndex: "wight",
          scopedSlots: { customRender: "wight" },
          width: "13%",
          key: 'rule',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {}
            };
            if (row.isMarge) {
              obj.attrs.rowSpan = this.calculationRowSpan(row);
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          }
        },
        {
          slots: { title: "prizeTitle" },
          dataIndex: "prizeTitle",
          key: 'prizeTitle',
          scopedSlots: { customRender: "prizeTitle" },
          width: "15%"
        },
        {
          slots: { title: "prizeType" },
          dataIndex: "prizeType",
          key: 'prizeType',
          scopedSlots: { customRender: "prizeType" },
          width: "13%"
        },
        {
          slots: { title: "relevancyId" },
          dataIndex: "relevancyId",
          key: 'relevancyId',
          scopedSlots: { customRender: "relevancyId" },
          width: "10%"
        },
        {
          slots: { title: "size" },
          dataIndex: "size",
          scopedSlots: { customRender: "size" },
          width: "18%",
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {}
            };
            if (row.isMarge) {
              obj.attrs.rowSpan = this.calculationRowSpan(row);
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          }
        },
        {
          slots: { title: "prizeImg" },
          dataIndex: "prizeImg",
          scopedSlots: { customRender: "prizeImg" },
          width: "20%"
        }
      ]
    };
  },
  props: ['setJackpotName'],
  methods: {
    // 上传excel并解析数据
    handleChangeFile({ file }) {
      this.fileList = file;
      if (this.fileList && this.fileList.response && this.fileList.status === "done") {
        const { code, data, msg } = this.fileList.response;
        if (code === "200") {
          this.currentJackpotId = data;
          this.getJackpotInfo(data);
        } else {
          this.$message.error(msg);
        }
      }
      if (this.fileList && this.fileList.response && this.fileList.status === "error") {
        const { code, data, msg } = this.fileList.response;
        if (code !== "200") {
          this.$message.error(msg);
        }
      }
    },
    // 根据活动id查询开工红包详情
    getJackpotInfo(id) {
      getStartPrizeInfoById(id).then(res => {
        const { code, data, msg } = res
        if (code === '200') {
          this.data = this.changeData(data)
          this.setJackpotName(data.pondTitle)
        } else {
          this.$message.error(msg)
        }
      });
    },
    // 下载模板
    downloadTemplate() {
      location.href = 'https://oss-workplace-prod.innoecos.cn/picture_prod/06097fe780810000_%E5%BC%80%E5%B7%A5%E7%BA%A2%E5%8C%85_%E5%A5%96%E6%B1%A0%E4%B8%8A%E4%BC%A0%E6%A8%A1%E6%9D%BF.xlsx'
    },
    // 接受父组件的数据
    setTableData (data, flag) {
      this.data = this.changeData(data)
      if (flag === 'view') {
        this.isShowUpload = false
      }
    },
    // 转化数据
    changeData(data) {
      const newData = [];
      if (!data) {
        return false;
      } else {
        data.pondPacketList.map((item, index) => {
          item.pondPrizeList.map((arr, index2) => {
            if (index2 === 0) {
              arr.isMarge = true;
            }
            // 后面的值是空的，所以防止被覆盖掉
            delete arr.size
            const info = { ...item, ...arr, flag: index };
            // delete info.pondPacketList;
            newData.push(info);
          });
        });
      }
      return newData;
    },
    // 计算要合并几个单元格
    calculationRowSpan(data) {
      const newData = this.data.filter(item => item.flag === data.flag);
      return newData.length;
    },
    handleCancel() {
      this.previewVisible = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.startDetail {
  margin-top: 30px;
  margin-bottom: 20px;

  .uploadClass {
    margin-right: 20px;
  }
}
</style>
