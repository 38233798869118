<template>
  <div>
    <ds-header :title="title"></ds-header>
    <div>
      <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="pondTitle" label="奖池名称" prop="pondTitle">
          <a-input
              v-model="form.pondTitle"
              :disabled="disable || disableJackpotName"
              style="width: 300px"
              :max-length="15"
              @blur="
          () => {
            $refs.pondTitle.onFieldBlur();
          }
        "
          />
        </a-form-model-item>
        <a-form-model-item label="奖池类型" prop="pondType">
          <a-select
              style="width: 300px"
              :disabled="disable"
              v-model="form.pondType"
              @change="changePondType"
              placeholder="请选择奖池类型"
          >
            <a-select-option :value="1">
              固定奖包<!-- 年终回馈 -->
            </a-select-option>
            <a-select-option :value="2">
              概率抽奖<!-- 开工红包 -->
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="所属项目" prop="projectId">
          <a-select
            style="width: 300px"
            :disabled="disable"
            v-model="form.projectId"
            placeholder="请选择所属项目"
            @change="findCrowds"
            @select="projectSelectEvent"
          >
            <a-select-option v-for="item in projectList" :value="item.id">
              {{ item.project_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="奖品详情" :required="true">
          <ul class="jackpotDetailClass" v-if="form.pondType === 1">
            <li class="jackpotDetailTopClass">
              <!-- <span>{{ form.pondType === 1 ? '年终回馈' : '开工红包' }}</span> -->
              <span>{{ form.pondType === 1 ? '固定奖包' : '概率抽奖' }}</span>
              <span v-show="!disable">
                <a-button type="link" @click="addRow">添加</a-button>
              </span>
            </li>
            <li>
              <a-table :pagination="false"  :columns="columns" :data-source="data" bordered>
                <span slot="packageId"><span class="requiredClass">*</span> 所属礼包</span>
                <span slot="prizeTitle"><span class="requiredClass">*</span> 奖品名称</span>
                <span slot="prizeType"><span class="requiredClass">*</span> 奖品类型</span>
                <span slot="relevancyId"><span class="requiredClass">*</span> 关联ID</span>
                <span slot="isGround"><span class="requiredClass">*</span> 是否兜底</span>
                <span slot="sendAmount"><span class="requiredClass">*</span> 已发数量</span>
                <span slot="residueAmount"><span class="requiredClass">*</span> 剩余数量</span>
                <span slot="size"><span class="requiredClass">*</span> 数量</span>
                <span slot="crowds"><span class="requiredClass">*</span> 所属人群</span>
                <span slot="probability"><span class="requiredClass">*</span> 中奖概率</span>
                <span slot="prizeImg"><span class="requiredClass"></span> 奖品图片</span>
                <template
                  slot="packageId"
                  slot-scope="text, record, index"
                >
                  <div key="packageId">
                    <a-select
                      v-if="record.editable"
                      style="margin: -5px 0;width:100px"
                      v-model="text"
                      :disabled="record.disable"
                      @change="handleChange(text, record.key, 'packageId')"
                    >
                      <a-select-option v-for="item in packageList" :value="item" :key="item">
                        {{ item }}
                      </a-select-option>
                    </a-select>
                    <template v-else>
                      {{ text }}
                    </template>
                  </div>
                </template>
                <template
                    slot="prizeTitle"
                    slot-scope="text, record, index"
                >
                  <div key="prizeTitle">
                    <a-input
                        v-if="record.editable"
                        style="margin: -5px 0;width:100px"
                        :value="text"
                        @change="e => handleChange(e.target.value, record.key, 'prizeTitle')"
                    />
                    <template v-else>
                      {{ text }}
                    </template>
                  </div>
                </template>
                <template
                    slot="prizeType"
                    slot-scope="text, record, index"
                >
                  <div key="prizeType">
                    <a-select
                        v-if="record.editable"
                        style="margin: -5px 0;width:100px"
                        v-model="text"
                        @change="handleChange(text, record.key, 'prizeType')"
                    >
                      <a-select-option :value="1">
                        优惠券
                      </a-select-option>
                      <a-select-option :value="2">
                        积分
                      </a-select-option>
                      <a-select-option :value="3">
                        现金
                      </a-select-option>
                    </a-select>
                    <template v-else>
                      {{ text === 1 ? '优惠券' : text === 2 ? '积分' : '现金' }}
                    </template>
                  </div>
                </template>
                <template
                    slot="relevancyId"
                    slot-scope="text, record, index"
                >
                  <div key="relevancyId">
                    <a-input
                        v-if="record.editable"
                        style="margin: -5px 0;width:100px"
                        :value="text"
                        :disabled="record.relevancyIdDisable"
                        @change="e => handleChange(e.target.value, record.key, 'relevancyId')"
                    />
                    <template v-else>
                      {{ text }}
                    </template>
                  </div>
                </template>
                <template
                    slot="isGround"
                    slot-scope="text, record, index"
                >
                  <div key="isGround">
                    <a-select
                        v-if="record.editable"
                        style="margin: -5px 0;width:100px"
                        v-model="text"
                        :defaultValue="text"
                        @change="handleChange(text, record.key, 'isGround')"
                    >
                      <a-select-option value="1">
                        是
                      </a-select-option>
                      <a-select-option value="2">
                        否
                      </a-select-option>
                    </a-select>
                    <template v-else>
                      {{ text === '1' ? '是' : '否' }}
                    </template>
                  </div>
                </template>
                <template
                    slot="sendAmount"
                    slot-scope="text, record, index"
                >
                  <div key="sendAmount">
                    <a-input-number
                        v-if="record.editable"
                        style="margin: -5px 0"
                        v-model="text"
                        :max="99999"
                        :min="0"
                        :disabled="record.disable"
                        @change="handleChange(text, record.key, 'sendAmount')"
                    />
                    <template v-else>
                      {{ text }}
                    </template>
                  </div>
                </template>
                <template
                    slot="residueAmount"
                    slot-scope="text, record, index"
                >
                  <div key="residueAmount">
                    <a-input-number
                        v-if="record.editable"
                        style="margin: -5px 0"
                        v-model="text"
                        :max="99999"
                        :min="0"
                        :disabled="record.disable"
                        @change="handleChange(text, record.key, 'residueAmount')"
                    />
                    <template v-else>
                      {{ text }}
                    </template>
                  </div>
                </template>
                <template
                    slot="size"
                    slot-scope="text, record, index"
                >
                  <div key="size">
                    <a-input-number
                        v-if="record.editable"
                        style="margin: -5px 0"
                        v-model="text"
                        :max="99999"
                        :min="0"
                        :disabled="record.disable"
                        @change="handleChange(text, record.key, 'size')"
                    />
                    <template v-else>
                      {{ text }}
                    </template>
                  </div>
                </template>
                <template
                    slot="crowds"
                    slot-scope="text, record, index"
                >
                  <div key="crowds">
                    <a-select
                        v-if="record.editable"
                        style="margin: -5px 0;width:200px;"
                        v-model="text"
                        :disabled="record.disable"
                        @change="handleChange(text, record.key, 'crowds')"
                    >
                      <a-select-option v-for="item in crowdsList" :key="item.id">
                        {{ item.category }}
                      </a-select-option>
                    </a-select>
                    <template v-else>
                      {{ text ? crowdsList.find(arr => arr.id === text).category : '' }}
                    </template>
                  </div>
                </template>
                <template
                    slot="probability"
                    slot-scope="text, record, index"
                >
                  <div key="probability">
                    <a-input-number
                        v-if="record.editable"
                        style="margin: -5px 0"
                        v-model="text"
                        :disabled="record.disable"
                        :max="100"
                        :min="0"
                        @change="handleChange(text, record.key, 'probability')"
                    />
                    <template v-else>
                      {{ text }}
                    </template>
                  </div>
                </template>
                <template
                    slot="prizeImg"
                    slot-scope="text, record, index"
                >
                  <div key="prizeImg">
                    <div class="clearfix" v-if="record.editable">
                      <a-upload
                          :action="IMG_API + '/oss/files'"
                          list-type="picture-card"
                          :file-list="record.imgList"
                          @preview="handlePreview"
                          :showUploadList="{ showPreviewIcon: false, showRemoveIcon: true }"
                          :before-upload="imgBeforeUpload"
                          @change="(data) => handleChangeImg(data,index)"
                      >
                        <div  v-if="record.imgList.length < 1">
                          <a-icon type="plus" />
                          <div class="ant-upload-text">
                            Upload
                          </div>
                        </div>
                      </a-upload>
                    </div>
                    <template v-else>
                      <img width="60px" height="60px" :src="text" alt="">
                    </template>
                  </div>
                </template>
                <template slot="operation" slot-scope="text, record, index">
                  <div class="editable-row-operations">
                    <span v-if="record.editable">
                      <a @click="save(record.key)">保存</a>
                      <a @click="cancel(record.key)">取消</a>
                    </span>
                    <span v-else>
                      <a :disabled="editingKey !== '' || disable" @click="() => edit(record.key)">编辑</a>
                      <a :disabled="disable" @click="deleteRow(index)">删除</a>
                    </span>
                  </div>
                </template>
                <template slot="contactInfo" slot-scope="text, record, index">
                  <div class="editable-row-operations">
                    {{ text }}
                  </div>
                </template>
              </a-table>
            </li>
          </ul>
          <ul v-else>
            <StartPrize ref="startDetail" :setJackpotName="setJackpotName" />
          </ul>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <div v-if="form.pondType === 1">
            <a-button v-show="!disable" type="primary" @click="onSubmit">
              保存
            </a-button>
            <a-button style="margin-left: 10px;" @click="resetForm">
              {{ disable ? '返回':'取消' }}
            </a-button>
          </div>
          <div v-else>
            <a-button style="margin-left: 10px;" @click="resetForm">
              返回
            </a-button>
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { addJackpot,findOneJackpot, editJackpot,selectCrowds,selectCrowdss ,getContactInfo,getContactPointInfo} from '@/api/yearEndActivities'
import { IMG_API } from "@/config";
import { groupBy } from "lodash";
import StartPrize from '../StartPrize/StartPrize'
import { projectName } from '@/api/modularManagement'
export default {
  name: "jackpotAdd",
  data() {
    return {
      // 所有项目
      projectList: [],
      // 奖包
      packageList: [1,2,3,4,5,6],
      // 奖池名称禁用
      disableJackpotName: false,
      data:[],
      cacheData:[],
      title: '新增奖池',
      disable: false,
      crowdsList: [],
      editingKey: '',
      id: '',
      IMG_API: IMG_API,
      form: {
        id: '',
        pondTitle: "",
        pondType: 1,
        pondPrizeList: [],
        // 所属项目
        projectId: undefined,
      },
      columns:[
        {
          dataIndex: 'packageId',
          slots: { title: 'packageId' },
          scopedSlots: { customRender: 'packageId' },
          width:'10%'
        },
        {
          dataIndex: 'prizeTitle',
          slots: { title: 'prizeTitle' },
          scopedSlots: { customRender: 'prizeTitle' },
          width:'10%'
        },
        {
          slots: { title: 'prizeType' },
          dataIndex: 'prizeType',
          scopedSlots: { customRender: 'prizeType' },
          width:'10%'
        },
        {
          slots: { title: 'relevancyId' },
          dataIndex: 'relevancyId',
          scopedSlots: { customRender: 'relevancyId' },
          width:'10%'
        },
        {
          slots: { title: 'isGround' },
          dataIndex: 'isGround',
          scopedSlots: { customRender: 'isGround' },
          width:'10%'
        },
        {
          slots: { title: 'size' },
          dataIndex: 'size',
          scopedSlots: { customRender: 'size', },
          width:'10%'
        },
        {
          slots: { title: 'crowds' },
          dataIndex: 'crowds',
          scopedSlots: { customRender: 'crowds' },
          width:'10%'
        },
        {
          slots: { title: 'prizeImg' },
          dataIndex: 'prizeImg',
          scopedSlots: { customRender: 'prizeImg' },
          width:'10%'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '70px',
          scopedSlots: { customRender: 'operation' },
        },
        {
          title: '关联信息',
          dataIndex: 'contactInfo',
          width: '150px',
          scopedSlots: { customRender: 'contactInfo' },
        },
      ],
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      other: "",

      tableInfo: [],
      rowData: {
        // 奖包id
        packageId: '1',
        // 奖品名称
        prizeTitle: '',
        // 奖品类型
        prizeType: 1,
        // 关联id
        relevancyId: '',
        // 是否兜底
        isGround: '2',
        //已发数量
        sendAmount:'',
        //剩余数量
        residueAmount:'',
        // 数量
        size: '',
        // 人群
        crowds: '',
        // 概率
        probability: '',
        // 奖品图片
        prizeImg: '',
        // 顺序
        sort: '',
        imgList:[],
        //关联信息
        contactInfo:""
      },
      rules: {
        pondTitle: [
          { required: true, message: "请输入奖池名称", trigger: "blur" },
          { max: 15, message: "奖池名称不能超过15字符", trigger: "blur" }
        ],
        pondType: [
          { required: true, message: '请选择奖池类型', trigger: 'change' }
        ],
        projectId: [
          { required: true, message: '请选择所属项目', trigger: 'change' }
        ]
      }
    };
  },
  components: {
    StartPrize
  },
  async beforeMount() {

  },
  async created() {
    // 获取所有项目
    await this.getProjectList();
    // await this.findCrowds();
    this.id = this.$route.query.id
    if (this.$route.query.type === 'view') {
      this.disable = true
      this.columns.splice(7,1);
      this.columns.splice(5,0, {
          slots: { title: 'sendAmount' },
          dataIndex: 'sendAmount',
          scopedSlots: { customRender: 'sendAmount' },
          width:'10%'
        },
        {
          slots: { title: 'residueAmount' },
          dataIndex: 'residueAmount',
          scopedSlots: { customRender: 'residueAmount' },
          width:'10%'
        });
      this.title = '查看奖池'
    }
    if (this.$route.query.type === 'edit') {
      this.title = '编辑奖池'
    }
    if (this.id) {
      this.form.id = this.id
      await this.findOne();
    }
  },
  methods: {
    /**
     * 获取所有项目
     */
    async getProjectList() {
      const res = await projectName()
      const { data: { items }, code } = res
      if(code === '200') {
        this.projectList = items
      }
    },
    // 检查是否有编辑未保存的
    checkHasEnable() {
      return this.data.some(item => Reflect.has(item,'editable'))
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // 检查是否有编辑未保存的
          if (this.checkHasEnable()) {
            this.$message.error("请先保存奖品详情！")
            return false;
          }
          // 检查数据
          if (this.checkData()) {
            if (this.form.id) {
              editJackpot(this.form).then(res => {
                if (res.code === '200') {
                  this.$message.success("操作成功！")
                  this.$router.back()
                } else {
                  this.$message.error(res.msg)
                }
              })
            } else {
              addJackpot(this.form).then(res => {
                if (res.code === '200') {
                  this.$message.success("操作成功！")
                  this.$router.back()
                }else {
                  this.$message.error(res.msg)
                }
              })
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 查询详情
    async findOne() {
      findOneJackpot(this.id).then(res => {
        if(res.code === '200') {
          const {pondTitle, pondType, pondPrizeList, projectId} = res.data
          // this.changePondType(pondType)
          this.form.pondTitle = pondTitle
          this.form.pondType = pondType
          this.form.projectId = projectId
          if (pondType === 1) {
            let newData = pondPrizeList
            const obj = newData.map((item, index) => {
              return {
                ...item,
                crowds: pondType === 1 ? item.rule : '',
                probability: pondType === 2 ? item.rule : '',
                key: index + 1,
                imgList: [{uid: ++index + '',name: 'image.png',status: 'done',url: item.prizeImg}],
              }
            })
            this.data = obj;
            // 深拷贝一份
            const newObj = JSON.parse(JSON.stringify(obj))
            this.cacheData = newObj
          } else {
            // 把值传到子组件中
            this.$nextTick(() => {
              this.$refs.startDetail.setTableData(res.data,'view');
            })
          }
        }
        
        this.findCrowds()
        const newData = [...this.data];
        newData.find(item => {
          this.save(item.key)

        });
      })
    },
    // 检查要提交的数据
    checkData() {
      try {
        if (this.data.length === 0) {
          this.$message.error("至少创建一条奖品数据！")
          throw Error()
        }
        this.data.forEach((item, index) => {
          if (!item.prizeTitle) {
            this.$message.error(`请填写奖品详情第${index + 1}行的奖品名称！`)
            throw Error()
          }
          if (!item.prizeType) {
            this.$message.error(`请选择奖品详情第${index + 1}行的奖品类型！`)
            throw Error()
          }
          if (item.prizeType !== 3 && !item.relevancyId) {
            this.$message.error(`请填写奖品详情第${index + 1}行的关联ID！`)
            throw Error()
          }
          if (!item.isGround) {
            this.$message.error(`请选择奖品详情第${index + 1}行的是否兜底！`)
            throw Error()
          }
          if(item.isGround === '2') {
            // if (!item.sendAmount) {
            //   this.$message.error(`请填写奖品详情第${index + 1}行的数量！`)
            //   throw Error()
            // }
            // if (!item.residueAmount) {
            //   this.$message.error(`请填写奖品详情第${index + 1}行的数量！`)
            //   throw Error()
            // }
            if (!item.size) {
              this.$message.error(`请填写奖品详情第${index + 1}行的数量！`)
              throw Error()
            }
            if (!item.crowds && this.form.pondType === 1) {
              this.$message.error(`请选择奖品详情第${index + 1}行的所属人群！`)
              throw Error()
            }
          }
          if (this.form.pondType === 2 && !item.probability && item.isGround === '2') {
            this.$message.error(`请填写奖品详情第${index + 1}行的中奖概率!`)
            throw Error()
          }
          // if (!item.prizeImg) {
          //   this.$message.error(`请上传奖品详情第${index + 1}行的奖品图片！`)
          //   throw Error()
          // }
        })
        if (this.form.pondType === 2) {
          const count =  this.data.reduce(function(prev,next){
            return prev + parseInt(next.probability)
          },0)
          if (count > 100) {
            this.$message.error("中奖概率不能超过100%！")
            throw Error()
          }
          if (count < 100) {
            this.$message.error("中奖概率之和必须等于100%！")
            throw Error()
          }
        }

        if (this.form.pondType === 1) {
          // 判断一个奖池至少有一个兜底
          const index = this.data.findIndex(item => item.isGround === '1')
          if(index < 0) {
            this.$message.error(`同一奖池至少有一个兜底奖品!`)
            throw Error()
          }
          const crowdsIsNullObj = this.data.filter(item => item.crowds !== '')
          const groupCrowds = groupBy(crowdsIsNullObj, function (item) {
            return item.crowds
          })
          // 获取所有对象中的key
          const crowdsIds = Object.keys(groupCrowds)
          if (crowdsIds.length !== this.crowdsList.length) {
              this.$message.error(`每个人群至少有一个奖品!`)
              throw Error()
          }
          //  - 同一奖池的所有优惠券ID不能重复
          if (!this.checkDataRelevancyId()) {
            this.$message.error(`同一奖池的优惠券ID不能重复!`)
            throw Error()
          }
          crowdsIds.forEach(item => {
            // const flag = groupCrowds[item].every(arr => arr.size === groupCrowds[item][0].size)
            const groupPackage = groupBy(groupCrowds[item], function (item) {
              return item.packageId
            })
            if(groupPackage[item] && groupPackage[item].length > 0) {
              const flag = groupPackage[item].every(arr => arr.size === groupPackage[item][0].size)
              if (!flag) {
                this.$message.error("同一奖池的同一人群的同一礼包的所有奖品【数量】必须一致")
                throw Error()
              }
            }
          })
        }
        const newData = this.data.map(item => {
          const type = this.form.pondType
          return {
            ...item,
            rule: type === 1 ? item.crowds : item.probability
          }
        })
        this.form.pondPrizeList = newData
      }catch (error) {
        return false;
      }
      return true;
    },
    /**
     * 判断奖池中的优惠券id是否重复
     */
    checkDataRelevancyId() {
      let relevancyIds = []
      this.data.forEach(item => {
        if(item.prizeType === 1) {
          relevancyIds.push(item.relevancyId)
        }
      })
      const set = new Set(relevancyIds)
      return (this.data.filter((arr) => arr.prizeType === 1 ).length) === set.size;
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.$router.back()
    },
    addRow () {
      let newData = JSON.parse(JSON.stringify(this.rowData))
      newData.key = (this.data.length + 1).toString()
      this.data.push(JSON.parse(JSON.stringify(newData)))
      this.cacheData.push(JSON.parse(JSON.stringify(newData)))
    },
    handleChange(value, key, column) {
      console.log(value, key, column,"value, key, column")
      const newData = JSON.parse(JSON.stringify(this.data))
      const target = newData.find(item => key === item.key);
      console.log(target,"target")
      if (target) {
        if (column === 'prizeType') {
          if (value === 3) {
            target['relevancyId'] = ''
            target['relevancyIdDisable'] = true
          } else {
            target['relevancyIdDisable'] = false
          }
        }
        if (column === 'isGround') {
          if (value === '1') {
            target['sendAmount'] = ''
            target['residueAmount'] = ''
            target['size'] = ''
            target['probability'] = ''
            target['crowds'] = ''
            target['packageId'] = ''
            target['disable'] = true
          } else {
            target['disable'] = false
          }
        }
        target[column] = value;
        this.data = newData;
      }
    },
    // 图片上传
    handleChangeImg({ fileList },index) {
      const newData = [...this.data]
      this.data[index].imgList = fileList
      if (this.data[index].imgList.length > 0 && fileList[0].status ==="done") {
        newData[index].prizeImg = fileList[0].response.redirect_uri
        newData[index].imgList = [{uid: '-1',url: fileList[0].response.redirect_uri}]
      } else {
        newData[index].prizeImg = ''
        // newData[index].imgList = []
      }
      this.data = newData
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    //上传文件之前校验图片大小
    imgBeforeUpload(file) {
      const that = this
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJPG = type === "png" || type === "jpg";
      if (!isJPG) {
        that.$message.error("上传图片只能是jpg、png格式!");
        return new Promise(function (resolve, reject) {return reject});
      }
      if (file.size/1024 > 500) {
        that.$message.error("上传图片不能大于500KB!");
        return new Promise(function (resolve, reject) {return reject});
      }
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          // const proportion = that.reductionTo(img.width, img.height);
          const valid = img.width === 688 && img.height === 220
          if (valid) {
            resolve();
          } else {
            that.$message.error("上传图片必须是688*220");
            return new Promise(function (resolve, reject) {return reject});
          }
          // valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
          () => {
            return file;
          },
          () => {
            that.$message.error("图片不规范，请按要求上传");
            return reject();
          }
      );
      return isJPG && isSize;
    },
    edit(key) {
      const newData = JSON.parse(JSON.stringify(this.data))
      const target = newData.find(item => key === item.key);
      this.editingKey = key;
      if (target) {
        target.editable = true;
        if (target.prizeType === 3) {
          target.relevancyIdDisable = true
        }
        if (target.isGround === '1') {
          target.disable = true
        }
        this.data = JSON.parse(JSON.stringify(newData))
        // 深拷贝
        this.cacheData = JSON.parse(JSON.stringify(newData))
      }
    },
    async save(key) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.key);
      
      // 校验数据
      if (!this.checkRowData(target)) {
        return false;
      }
      console.log(target,"target")
      this.editingKey = '';
      if (target) {
        if(target.prizeType===1){
        await getContactInfo(target.relevancyId).then(res=>{
          target.contactInfo = res.data
        })
      }else if(target.prizeType===2){
        await getContactPointInfo(target.relevancyId).then(res=>{
          target.contactInfo = res.data
        })
      }else{
        target.contactInfo = ""
      }
        delete target.editable;
        let sortData = []
        const groupCrowd = groupBy(newData, function(item){
         return item.crowds
        })
        const crowds = Object.keys(groupCrowd)
        crowds.forEach(item => {
          sortData = [...sortData, ...groupCrowd[item].sort((a, b) => a.packageId - b.packageId )]
        })
        this.data = sortData;
        // 深拷贝
        const newCacheData = JSON.parse(JSON.stringify(sortData))
        this.cacheData = newCacheData;
      }
    },

    // 校验数据
    checkRowData(data) {
      try {
        if (!data.prizeTitle) {
          this.$message.error("请填写奖品名称！")
          throw Error()
        }
        if (!data.prizeType) {
          this.$message.error("请选择奖品类型！")
          throw Error()
        }
        if (data.prizeType !== 3 && !data.relevancyId) {
          this.$message.error("请填写关联ID！")
          throw Error()
        }
        if (!data.isGround) {
          this.$message.error("请选择是否兜底！")
          throw Error()
        }
        if(data.isGround === '2') {
          if (!data.packageId) {
            this.$message.error("请选择所属奖包！")
            throw Error()
          }
          // if (!data.sendAmount) {
          //   this.$message.error("请填写已发数量")
          //   throw Error()
          // }
          // if (!data.residueAmount) {
          //   this.$message.error("请填写剩余数量")
          //   throw Error()
          // }
          // data.size = data.sendAmount + data.residueAmount
          if (!data.size) {
            this.$message.error("请填写数量")
            throw Error()
          }
          if (!data.crowds && this.form.pondType === 1) {
            this.$message.error("请选择所属人群！")
            throw Error()
          }
        }
        if (this.form.pondType === 2 && !data.probability && data.isGround === '2') {
          this.$message.error("请填写中奖概率！")
          throw Error()
        }
        // if (!data.prizeImg) {
        //   this.$message.error("请上传奖品图片！")
        //   throw Error()
        // }
        return true;
      } catch (error) {
        return false;
      }
    },

    cancel(key) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.key);
      const cacheTarget = this.cacheData.find(item => key === item.key)
      this.editingKey = '';
      if (target) {
        delete target.editable;
        delete cacheTarget.editable
        this.data = this.cacheData;
      }
    },
    changePondType(type) {
      if (type === 1) {
        this.columns.splice(6,1,{
          slots: { title: 'crowds' }, dataIndex: 'crowds',scopedSlots: { customRender: 'crowds' },width:'10%'
          }
        )
        this.disableJackpotName = false
      } else {
        // 开工红包支支持上传excel，要禁用奖池名称
        this.disableJackpotName = true
        this.form.pondTitle = ''
        // this.columns.splice(5,1, {
        //   slots: { title: 'probability' }, dataIndex: 'probability', scopedSlots: { customRender: 'probability' },width:'20%'
        // })
      }
    },
    // 删除行
    deleteRow(i) {
      const newData = JSON.parse(JSON.stringify(this.data))
      const filterData = newData.filter((item,index) => index !== i)
      const newFilterData = filterData.map((item, index) => {
        return {
          ...item,
          key: index + 1
        }
      })
      this.data = JSON.parse(JSON.stringify(newFilterData))
      this.cacheData  = JSON.parse(JSON.stringify(newFilterData))
    },
    // 查询所有人群
    async findCrowds() {
      selectCrowdss(this.form.projectId,3).then(res => {
        if (res.code === '200') {
          this.crowdsList = res.data
        }
      })
    },
    //
    projectSelectEvent(val) {
      if(this.data.length) {
          this.data = this.data.map((item) => {
            return {
              ...item,
              crowds: ''
            }
          })
      }
    },
    // 开工红包时给奖池名称赋值
    setJackpotName(data) {
      this.form.pondTitle = data
    }
  }
};
</script>

<style lang="scss" scoped>
.jackpotDetailClass {
  padding-left: 0!important;
  padding-top: 30px;
  & > li {
    list-style-type: none;
  }
  .jackpotDetailTopClass {
    display: flex;
    justify-content: space-between;
  }
}
.editable-row-operations a {
  margin-right: 8px;
}
.requiredClass {
  color: red;
}
</style>
