import {
    fetchApi
  } from "@/utils/axios";
  import URL from "@/api/urlConfig"

// 模块管理列表
export const modularList = (data) => {
    return fetchApi(URL.MODULAR_LIST, data, 'post', 'data')
}

// 获取项目名称
export const projectName = () => {
	return fetchApi(URL.BINDING_PROJECT_LIST, null)
}

// 模块管理新增
export const modularAdd = (data) => {
    return fetchApi(URL.MODULAR_ADD, data, 'post', 'data')
}

// 新的模块管理新增
export const newModularAdd = (data) => {
    return fetchApi(URL.NEW_MODULAR_ADD, data, 'post', 'data')
}

//服务列表下拉框
export function selectservicelistbyname(params) {
    return fetchApi(URL.MODULAR_SERVICE, params, "get")
}

//服务列表下拉框
export function modularInfo(id) {
    return fetchApi(URL.MODULAR_INFO+'/'+id, {})
}

// 模块管理修改
export const modularUpdate = (data) => {
    return fetchApi(URL.MODULAR_UPDATE, data, 'put', 'data')
}

// 模块管理删除
export function modularDel(id) {
    return fetchApi(URL.MODULAR_DELETE+'/'+id+'/1', {})
}

// v2---模块管理删除
export function newModularDel(id) {
    return fetchApi(URL.NEW_MODULAR_DELETE+'/'+id + '/1', {})
}

// 模块管理启用禁用
export const modularRestart = (id, status) => {
    return fetchApi(URL.MODULAR_UPDATE+'/'+id+'/'+status, {}, 'get')
}
// v2 禁用启用
export const newModularRestart = (id, status) => {
    return fetchApi(URL.NEW_MODULAR_RESTART+'/'+id+'/'+status, {}, 'get')
}

// 模块详情
export const newModularInfo = (id) => {
    return fetchApi(URL.MODULAR_INFO_BY_ID+'/'+id, {}, 'get')
}

// v2模块修改
export const newModularUpdate = (data) => {
    return fetchApi(URL.NEW_MODULAR_UPDATE, data, 'put', 'data')
}

// v2模块管理列表
export const newModularList = (data) => {
    return fetchApi(URL.NEW_MODULAR_LIST, data, 'post', 'data', true)
}
