import { fetchApi } from "@/utils/axios";
import http from "@/utils/axios";
import URL from "@/api/urlConfig"


//用户详情
export const getKeyWordList = (data) => {
  return fetchApi( URL.GET_KEY_WORD_LIST + '/' + data.page + '/' + data.size + '?' + 'projectId=' + data.projectId +'&' + 'keyWord=' + data.keyword, {}, "get");
};
// 新增关键词组
export const insertKeyWordGroup = (data) => {
  return fetchApi(URL.INSERT_KEY_WORD_GROUP, data,'post', 'data')
}

// 关键词详情
export const getKeyWordDetailById = (id) => {
  return fetchApi(URL.GET_KEY_WORD_DETAIL_BY_ID + '/' + id, {}, 'post')
}

// 添加关键词
export const insertKeyWord = (data) => {
  return fetchApi(URL.INSERT_KEY_WORD, data, 'post','data')
}

// 删除关键词
export const deleteKeyWord =(id) => {
  return fetchApi(URL.DELETE_KEY_WORD + '/' + id + '/' + 1,{},'post')
}

// 禁用启用
export const restartKeyWord = (data) => {
  return fetchApi(URL.RESTART_KEY_WORD,data, 'post','data')
}

// 编辑关键词组
export const updateKeyWordById = (data) => {
  return fetchApi(URL.UPDATE_KEY_WORD_BY_ID,data,'post','data')
}

/**
 * 奖池管理
 */
export const jackpotList = (data) => {
  return fetchApi(URL.JACKPOT_LIST,data,'get')
}

// 新增奖池
export const addJackpot = (data) =>{
  return fetchApi(URL.INSERT_JACKPOT,data,'post','data')
}

// 删除奖池
export const deleteJackpot = (id) => {
  return fetchApi(URL.DELETE_JACKPOT_BY_ID + '/' + id,{},'delete')
}

// 查询奖池详情
export const findOneJackpot = (id) => {
  return fetchApi(URL.FIND_ONE_JACKPOT + '/' + id, {}, 'GET')
}

// 编辑奖池
export const editJackpot = (data) => {
  return fetchApi(URL.UPDATE_JACKPOT,data,'PUT','data')
}

// 根据项目id查询所有人群
export const selectCrowds = (projectId) => {
  return fetchApi(URL.SELECT_ALL_CROWDS_BY_PROJECT_ID + `/${projectId}`)
}
// 根据项目id查询所有人群
export const selectCrowdss = (projectId,type) => {
  return fetchApi(URL.SELECT_ALL_CROWDS_BY_PROJECT_ID + `/${projectId}?type=${type}`)
}
/**
 * 活动管理
 */
export const getYearEndActivityList = (data) => {
  return fetchApi(URL.GET_YEAR_END_ACTIVITIES,data,'GET')
}

// 查询所有关键词组
export const selectKeyWordGroupList = (id) => {
  return fetchApi(URL.SELECT_ALL_KEY_WORD_LIST + '?projectId=' + id ,{},'GET')
}

// 编辑年终活动
export const updateYearEndActivity = (data) => {
  return fetchApi(URL.UPDATE_YEAR_END_ACTIVITY,data,'put','data')
}

// 添加年终活动
export const insertYearEndActivity = (data) => {
  return fetchApi(URL.INSERT_YEAR_END_ACTIVITY,data,'post','data')
}

// 查询详情
export const selectYearEndActivityById = (id) => {
  return fetchApi(URL.SELECT_YEAR_END_ACTIVITY_BY_ID + '/' + id,{},'GET')
}

// 禁用启用
export const updateActivityStatus = (data) => {
  return fetchApi(URL.UPDATE_ACTIVITY_STATUS + '?activityId='  + data.activityId + '&state='+ data.state,{},'PUT','data')
}

// 活动数据
export const getYearEndActivityDataList = (data) => {
  return fetchApi(URL.GET_ACTIVITY_DATA, data, 'post', 'data')
}

// 战队pk活动数据
export const getPkActivityDataList = (data) => {
  return fetchApi(URL.GET_PK_ACTIVITY_DATA, data, 'post', 'data')
}

// 获得开工红包奖池数据
export const getStartPrizeInfoById = (id) => {
  return fetchApi(URL.GET_ACTIVITY_INFO_BY_ID + '/' + id,{},'GET')
}

// 导出活动数据
export const exportActivityData = (data) => {
  return http({
    url: URL.EXPORT_ACTIVITY_DATA,
    method: "post",
    data: data,
    responseType: "blob",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    }
  })
  // return fetchApi(URL.EXPORT_ACTIVITY_DATA, data, 'post', 'data')
}

/**
 * 导出pk活动数据
 */
export const exportPkActivityData = (data) => {
  return http({
    url: URL.EXPORT_PK_ACTIVITY_DATA,
    method: "post",
    data: data,
    responseType: "blob",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    }
  })
}

/**
 * 发财树活动导出
 */
export const exportMoneyTreeData = (data) => {
  return http({
    url: URL.EXPORT_MONEY_TREE_ACTIVITY_DATA,
    method: "post",
    data: data,
    responseType: "blob",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    }
  })
}


/**
 * 获取用户积分详情list
 */
export const getUserIntegralInfoById = (data) => {
  return fetchApi(URL.GET_USER_INTEGRAL_BY_ID + `/${data.userId}/${data.pageNo}/${data.pageSize}`,)
}

/**
 * 获取奖池列表根据项目id
 */
export const selectJackpotListByProjectId = (projectId) => {
  return fetchApi(URL.SELECT_JACKPOT_LIST_BY_PROJECT_ID + `/${projectId}` )
}

/**
 * 查询战队数据
 */
export const selectTeamData = (data) => {
  return fetchApi(URL.SELECT_TEAMS_DATA, data, 'POST', 'data');
}

//关联信息 (优惠卷)
export const getContactInfo = (data) => {
  return fetchApi(URL.GET_CONTACT_INFO+`/${data}`)
}
//关联信息 (优惠卷)
export const getContactPointInfo = (data) => {
  return fetchApi(URL.GET_CONTACT_POIN_INFO+`/${data}`)
}



/**
 * 获取开工活动的活动数据
 */
export const getStartActivitiesData = (data) => {
  return fetchApi(URL.GET_START_ACTIVITY_DATA, data, 'POST', 'data')
}